import { useContext, useState } from 'react'
import useApi from './useApi'
import { ResourceContext } from '../contexts/ResourceContext'
import Absence, { NewAbsence, ApiAbsence, mapApiAbsenceToAbsence } from '../types/Absence'
import useFormatter from './useFormatter'

interface ReturnProps {
  getAbsences: (force?: boolean) => Promise<void>
  postAbsence: (data: NewAbsence) => Promise<boolean>
  loading: boolean
  error: string | null
  absences: Absence[] | null
}

const useAbsences = (): ReturnProps => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const { get, post, endpoints } = useApi()
  const { formatDate } = useFormatter()
  const { setAbsences, absences } = useContext(ResourceContext)

  const getAbsences = async (force = false): Promise<void> => {
    if (absences !== null && !force) {
      return
    }
    setLoading(true)
    get(endpoints.absencesPath)
      .then((data) => {
        const response = data as { Absences?: ApiAbsence[], absences?: ApiAbsence[] }
        const apiAbsences: ApiAbsence[] = response.Absences ?? response.absences ?? []
        const mappedAbsences = apiAbsences.map(mapApiAbsenceToAbsence)
        setAbsences(mappedAbsences)
      })
      .catch((error) => {
        setError(error instanceof Error ? error.message : String(error))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const postAbsence = async (data: NewAbsence): Promise<boolean> => {
    setLoading(true)
    setError(null)
    const filename = data.AttachmentFilename?.split('.')
    const formattedData = {
      StartDate: formatDate(data.StartDate, 'absencePostDate'),
      EndDate: formatDate(data.EndDate, 'absencePostDate'),
      ICD: data.ICD !== null && data.ICD !== '' ? data.ICD : '0',
      GivenDate: formatDate(data.GivenDate, 'absencePostDate'),
      CompanyId: data.CompanyId,
      Image: data.AttachmentBase64,
      FileExtension:
        Array.isArray(filename) && filename.length > 0 ? filename[filename.length - 1] : ''
    }

    try {
      await post(endpoints.newAbsencePath, JSON.stringify(formattedData))
      setLoading(false)
      return true
    } catch (error) {
      setLoading(false)
      setError(error instanceof Error ? error.message : String(error))
      return false
    }
  }

  return {
    getAbsences,
    postAbsence,
    loading,
    error,
    absences
  }
}

export default useAbsences
