import endpoints from '../config/endpoints'
import apiConfig from '../config/apiConfig'
import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import fileDownload from 'js-file-download'
import { ResourceContext } from '../contexts/ResourceContext'

interface ReturnProps {
  endpoints: typeof endpoints
  get: (path: string) => Promise<any>
  getFile: (path: string, name: string) => Promise<void>
  post: (path: string, data?: any, headers?: any) => Promise<any>
  delete_: (path: string) => Promise<void>
}

const useApi = (): ReturnProps => {
  const { refreshRequestTime, signOut } = useContext(AuthContext)
  const { resetResources } = useContext(ResourceContext)

  const post = async (path: string, data: any = null, headers: any = null): Promise<any> => {
    return await apiFetch(path, 'POST', data, headers)
  }

  const get = async (path: string): Promise<any> => {
    return await apiFetch(path)
  }

  const delete_ = async (path: string): Promise<any> => {
    return await apiFetch(path, 'DELETE')
  }

  const getFile = async (path: string, name: string): Promise<void> => {
    const data = await apiFetch(path, 'GET', null, null, true)
    fileDownload(data, name)
  }

  const resolveHeaders = (headers: any = null, blob: boolean = false): any => {
    const defaultHeaders = blob
      ? {
          Accept: 'application/json',
          'Cache-Control': 'no-store'
        }
      : {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Cache-Control': 'no-store'
        }
    return { ...defaultHeaders, ...headers }
  }

  const apiFetch = async (
    path: string,
    method: string = 'GET',
    data: any = null,
    headers: any = null,
    blob: boolean = false
  ): Promise<any> => {
    if (path !== endpoints.pingPath) {
      refreshRequestTime()
    }

    const baseUrl =
      path.startsWith('login/') || path.startsWith('api/v1.0/auth/')
        ? apiConfig.apiUrl
        : apiConfig.apiUrl

    const url = `${baseUrl}/${path}`
    let response = await fetch(url, {
      method,
      headers: resolveHeaders(headers, blob),
      body: data,
      credentials: 'include',
      mode: 'cors'
    })

    if (!response.ok) {
      if (response.status === 401) {
        // Don't attempt refresh if we're already trying to refresh or logging out
        if (path === endpoints.refreshPath || path === endpoints.logoutPath) {
          signOut()
          throw new Error('Unauthorized')
        }

        // Attempt to refresh the token
        try {
          const refreshResponse = await fetch(`${apiConfig.apiUrl}/${endpoints.refreshPath}`, {
            method: 'POST',
            headers: resolveHeaders(),
            credentials: 'include',
            mode: 'cors'
          })

          if (!refreshResponse.ok) {
            // If refresh fails, logout and redirect
            try {
              await fetch(`${apiConfig.apiUrl}/${endpoints.logoutPath}`, {
                method: 'POST',
                headers: resolveHeaders(),
                credentials: 'include',
                mode: 'cors'
              })
            } catch (e) {
              console.error('Logout request failed:', e)
            }
            resetResources()
            signOut()
            throw new Error('Unauthorized')
          }

          // If refresh successful, retry the original request
          response = await fetch(url, {
            method,
            headers: resolveHeaders(headers, blob),
            body: data,
            credentials: 'include',
            mode: 'cors'
          })

          if (!response.ok) {
            const body = await response.text()
            throw new Error(`${response.status}: ${body}`)
          }
        } catch (error) {
          signOut()
          throw new Error('Unauthorized')
        }
      } else {
        const body = await response.text()
        throw new Error(`${response.status}: ${body}`)
      }
    }

    if (blob) {
      return await response.blob()
    }

    try {
      return await response.json()
    } catch (e) {
      return null
    }
  }

  return {
    endpoints,
    get,
    getFile,
    post,
    delete_
  }
}

export default useApi
